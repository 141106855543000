import { IconChevronLeft, IconChevronRight } from "@/components/mvicons";
import { motion } from "framer-motion";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { mobileRemindS1Pages } from "@/constants/mobile-remind-s1-pages.ts";
import { tabletRemindS1Pages } from "@/constants/tablet-remind-s1-pages.ts";
import { useScreenWidth } from "@/hooks/use-screen-width.ts";
import { Navigation, Pagination } from "swiper/modules";

const TapReader = () => {
	const [animateDetails, setAnimateDetails] = useState(false); // Control animation state
	const [showDetails, setShowDetails] = useState(true);
	const [step, setStep] = useState(1);
	const [clicked, setClicked] = useState(false);
	const screenWidth = useScreenWidth();

	const isMobile = screenWidth < 640;
	const isTablet = screenWidth > 640 && screenWidth < 768;

	const handleThirdButtonClick = () => {
		setClicked(true);
		setAnimateDetails(true); // animate top section details
		setTimeout(() => {
			setShowDetails(false); // hide details permanently
		}, 500); // match this duration with the animation duration
	};

	const StepButtons = () => {
		return (
			<div className="z-[4] absolute inset-0 w-full h-full">
				{/* Step 1 Button */}
				{step === 1 && (
					<motion.button
						type="button"
						className="absolute right-[10%] top-1/2 flex flex-row items-center w-auto"
						initial={{ x: 0, y: "-50%", opacity: 1 }}
						animate={{ x: 0, opacity: 1 }}
						transition={{
							duration: 0.2,
							ease: [0.33, 1, 0.68, 1],
						}}
						onClick={() => setStep(2)}
					>
						<div className="inline-flex flex-col w-auto">
							<span className="text-right text-white">
								Click the RIGHT SIDE
							</span>
							<span className="text-right text-white">to Go Forward</span>
						</div>
						<div className="inline-flex text-white">
							<IconChevronRight size={70} stroke={5} />
						</div>
					</motion.button>
				)}

				{/* Step 2 Button */}
				{step === 2 && !clicked && (
					<motion.button
						type="button"
						className="absolute left-[10%] top-1/2 flex flex-row items-center w-auto"
						initial={{ x: -50, y: "-50%", opacity: 1 }}
						animate={{ x: 0, opacity: 1 }}
						transition={{
							duration: 0.2,
							ease: [0.33, 1, 0.68, 1],
						}}
						onClick={() => setStep(3)}
					>
						<div className="inline-flex text-white">
							<IconChevronLeft size={70} stroke={5} />
						</div>
						<div className="inline-flex flex-col w-auto">
							<span className="text-left text-white">Click the LEFT SIDE</span>
							<span className="text-left text-white">to Go Back</span>
						</div>
					</motion.button>
				)}

				{/* Step 3 Button */}
				{step === 3 && !clicked && (
					<motion.button
						type="button"
						className="absolute right-[10%] top-1/2 flex flex-row items-center w-auto"
						initial={{ x: 50, y: "-50%", opacity: 1 }}
						animate={clicked ? { x: 50, opacity: 0 } : { x: 0, opacity: 1 }}
						transition={{
							duration: 0.2,
							ease: [0.33, 1, 0.68, 1],
						}}
						onClick={handleThirdButtonClick}
					>
						<div className="inline-flex flex-col w-auto">
							<span className="text-right text-lg font-bold text-white uppercase italic">
								Let's Go
							</span>
						</div>
						<div className="inline-flex text-white">
							<IconChevronRight size={70} stroke={5} />
						</div>
					</motion.button>
				)}
			</div>
		);
	};

	return (
		<div className="relative flex flex-col h-full w-full bg-black">
			{/* tap reader preview */}
			{showDetails && <StepButtons />}

			{/* reader ui contents */}
			{showDetails && (
				<motion.div
					className="z-[3] absolute top-6 left-1/2 inline-flex flex-col w-auto gap-6"
					initial={{ y: 0, x: "-50%", opacity: 1 }}
					animate={
						animateDetails ? { y: -100, opacity: 0 } : { y: 0, opacity: 1 }
					}
					transition={{
						duration: 0.5,
						ease: [0.33, 1, 0.68, 1],
					}}
				>
					<div className="flex flex-col justify-center items-center w-full gap-3">
						<div className="flex justify-center items-center w-full p-4 rounded-md bg-white/30 text-white">
							Brand Name
						</div>
						<div className="inline-flex flex-row items-center divide-x-2">
							<span className="px-2 uppercase text-sm text-white/70 leading-tight">
								Season 1
							</span>
							<span className="px-2 uppercase text-sm text-white/70 leading-tight">
								Episode 1
							</span>
						</div>
					</div>
					<div className="flex flex-row items-center w-full">
						<span className="font-bold text-3xl text-white">11+</span>
						<div className="inline-flex flex-col w-auto py-1 pl-4 ml-4 border-l-2 border-white">
							<span className="text-white/70 leading-tight">
								Cartoon Violence
							</span>
							<span className="text-white/70 leading-tight">Mature Themes</span>
							<span className="text-white/70 leading-tight">
								Some Rating Item
							</span>
							<span className="text-white/70 leading-tight">
								Another Rating item
							</span>
						</div>
					</div>
				</motion.div>
			)}

			{showDetails && (
				<motion.div
					className="z-[2] absolute inset-0 w-full h-full bg-black"
					initial={{ opacity: 1 }}
					animate={animateDetails ? { opacity: 0 } : { opacity: 1 }}
					transition={{
						duration: 0.5,
						ease: [0.33, 1, 0.68, 1],
					}}
				/>
			)}

			{/* actual page viewer */}
			<div className="z-[1] flex justify-center items-center w-screen h-full">
				<Swiper
					slidesPerView={1}
					spaceBetween={0}
					allowTouchMove={false}
					speed={0}
					pagination={{
						type: "progressbar", // Define type
						clickable: false, // Make it clickable
					}}
					navigation={{
						enabled: true,
						prevEl: ".swiper-button-prev", // Customize previous button
						nextEl: ".swiper-button-next", // Customize next button
					}}
					modules={[Pagination, Navigation]} // Include necessary modules
					className="tap-reader w-screen h-full"
				>
					<div className="z-50 absolute inset-0 grid grid-cols-2 w-full h-full gap-1">
						<div className="swiper-button-prev col-span-1 inline-flex justify-center items-center w-full h-full bg-transparent text-white" />
						<div className="swiper-button-next col-span-1 inline-flex justify-center items-center w-full h-full bg-transparent text-white" />
					</div>
					{isMobile
						? mobileRemindS1Pages.map(({ src, altText }, index) => (
								<SwiperSlide
									key={index}
									className="flex justify-center items-center w-auto h-full bg-black"
								>
									<div className="flex w-auto h-full">
										<img
											className="w-auto h-full object-contain"
											src={src}
											alt={altText}
										/>
									</div>
								</SwiperSlide>
							))
						: tabletRemindS1Pages.map(({ src, altText }, index) => (
								<SwiperSlide
									key={index}
									className="flex justify-center items-center w-full h-full bg-black"
								>
									<div className="flex max-w-full max-h-full">
										<img
											className="w-full h-auto max-h-full object-contain"
											src={src}
											alt={altText}
										/>
									</div>
								</SwiperSlide>
							))}
				</Swiper>
			</div>
		</div>
	);
};

export default TapReader;
