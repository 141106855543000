import { collectionsQueryOptions } from "@/api/queries/collection-queries.ts";
import { profileQueryOptions } from "@/api/queries/profile-queries.ts";
import BadgeModal from "@/components/badge-modal.tsx";
import CollectionBadges from "@/components/collections/collection-badges";
import { Collections } from "@/components/collections/collections.tsx";
import { Switch } from "@/components/core/forms/switch.tsx";
import { useModal } from "@/components/core/modals/use-modal.ts";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { useState } from "react";

export const Route = createFileRoute("/_profile/collections")({
	component: Page,
	loader: ({ context: { queryClient } }) => {
		return queryClient.ensureQueryData(collectionsQueryOptions());
	},
});

function Page() {
	const modal = useModal();
	const [showAllItems, setShowAllItems] = useState(false);
	/*const [showAllBadges, setShowAllBadges] = useState(false);*/
	const collectionQuery = useSuspenseQuery(collectionsQueryOptions());
	const collections = collectionQuery.data;
	const profileQuery = useQuery(profileQueryOptions());
	const profile = profileQuery.data;

	/*const handleShowAllBadges = () => {
		setShowAllBadges((prev) => !prev);
	};*/

	return (
		<div className="flex flex-col w-full gap-6">
			<div className="flex flex-col w-full gap-4">
				<div className="flex flex-row items-start sm:items-end justify-between gap-2 sm:gap-4">
					<div className="inline-flex flex-col w-auto">
						<span className="text-sm font-semibold text-white">
							Your Badges
						</span>
					</div>
					<div className="inline-flex flex-row items-center w-auto gap-1">
						<span className="text-sm text-white/50">+16 |</span>
						<button
							onClick={() => {
								modal.open({
									title: "Wallet Options Modal",
									size: "lg",
									component: <BadgeModal />,
								});
							}}
							type="button"
							className="text-sm text-white/50 hover:text-white"
						>
							See All
						</button>
						{/*<button
							onClick={handleShowAllBadges}
							type="button"
							className="text-sm text-white/50 hover:text-white"
						>
							{showAllBadges ? "Show Less" : "Show All"}
						</button>*/}
					</div>
				</div>
				<CollectionBadges />
			</div>

			<div className="flex flex-col w-full gap-4">
				<div className="flex flex-row items-start sm:items-end justify-between gap-2 sm:gap-4">
					<div className="inline-flex flex-col w-auto">
						<span className="text-sm font-semibold text-white">
							Your Collection
						</span>
					</div>
					<div className="inline-flex flex-row items-center w-auto gap-3">
						<span className="text-sm text-white/50">Show what's missing</span>
						<Switch checked={showAllItems} onCheckedChange={setShowAllItems} />
					</div>
				</div>

				<Collections
					user={profile}
					collections={collections.items}
					showAllItems={showAllItems}
				/>
			</div>
		</div>
	);
}
