export type ImageData = {
	src: string;
	altText: string;
};

export const mobileDarklandS1Pages: ImageData[] = [
	{
		src: "/mv-darkland-s1/mobile/704897495-mv_darkland_s1_ep1a_scrollstory_1.jpg",
		altText: "Darkland Season 1",
	},
	{
		src: "/mv-darkland-s1/mobile/704897500-mv_darkland_s1_ep1a_scrollstory_2.jpg",
		altText: "Darkland Season 1",
	},
	{
		src: "/mv-darkland-s1/mobile/704897502-mv_darkland_s1_ep1a_scrollstory_3.jpg",
		altText: "Darkland Season 1",
	},
	{
		src: "/mv-darkland-s1/mobile/704897503-mv_darkland_s1_ep1a_scrollstory_4.jpg",
		altText: "Darkland Season 1",
	},
	{
		src: "/mv-darkland-s1/mobile/704897504-mv_darkland_s1_ep1a_scrollstory_5.jpg",
		altText: "Darkland Season 1",
	},
	{
		src: "/mv-darkland-s1/mobile/704897505-mv_darkland_s1_ep1a_scrollstory_6.jpg",
		altText: "Darkland Season 1",
	},
	{
		src: "/mv-darkland-s1/mobile/704897508-mv_darkland_s1_ep1a_scrollstory_7.jpg",
		altText: "Darkland Season 1",
	},
	{
		src: "/mv-darkland-s1/mobile/704897509-mv_darkland_s1_ep1a_scrollstory_8.jpg",
		altText: "Darkland Season 1",
	},
	{
		src: "/mv-darkland-s1/mobile/704897510-mv_darkland_s1_ep1a_scrollstory_9.jpg",
		altText: "Darkland Season 1",
	},
	{
		src: "/mv-darkland-s1/mobile/704897512-mv_darkland_s1_ep1a_scrollstory_10.jpg",
		altText: "Darkland Season 1",
	},
	{
		src: "/mv-darkland-s1/mobile/704897514-mv_darkland_s1_ep1a_scrollstory_11.jpg",
		altText: "Darkland Season 1",
	},
	{
		src: "/mv-darkland-s1/mobile/704897517-mv_darkland_s1_ep1a_scrollstory_12.jpg",
		altText: "Darkland Season 1",
	},
	{
		src: "/mv-darkland-s1/mobile/704897520-mv_darkland_s1_ep1a_scrollstory_13.jpg",
		altText: "Darkland Season 1",
	},
	{
		src: "/mv-darkland-s1/mobile/704897523-mv_darkland_s1_ep1a_scrollstory_14.jpg",
		altText: "Darkland Season 1",
	},
	{
		src: "/mv-darkland-s1/mobile/704897524-mv_darkland_s1_ep1a_scrollstory_15.jpg",
		altText: "Darkland Season 1",
	},
	{
		src: "/mv-darkland-s1/mobile/704897526-mv_darkland_s1_ep1a_scrollstory_16.jpg",
		altText: "Darkland Season 1",
	},
	{
		src: "/mv-darkland-s1/mobile/704897530-mv_darkland_s1_ep1a_scrollstory_17.jpg",
		altText: "Darkland Season 1",
	},
	{
		src: "/mv-darkland-s1/mobile/704897535-mv_darkland_s1_ep1a_scrollstory_18.jpg",
		altText: "Darkland Season 1",
	},
	{
		src: "/mv-darkland-s1/mobile/704897537-mv_darkland_s1_ep1a_scrollstory_19.jpg",
		altText: "Darkland Season 1",
	},
	{
		src: "/mv-darkland-s1/mobile/704897539-mv_darkland_s1_ep1a_scrollstory_20.jpg",
		altText: "Darkland Season 1",
	},
	{
		src: "/mv-darkland-s1/mobile/704897542-mv_darkland_s1_ep1a_scrollstory_21.jpg",
		altText: "Darkland Season 1",
	},
	{
		src: "/mv-darkland-s1/mobile/704897545-mv_darkland_s1_ep1a_scrollstory_22.jpg",
		altText: "Darkland Season 1",
	},
	{
		src: "/mv-darkland-s1/mobile/704897548-mv_darkland_s1_ep1a_scrollstory_23.jpg",
		altText: "Darkland Season 1",
	},
	{
		src: "/mv-darkland-s1/mobile/704897550-mv_darkland_s1_ep1a_scrollstory_24.jpg",
		altText: "Darkland Season 1",
	},
	{
		src: "/mv-darkland-s1/mobile/704897551-mv_darkland_s1_ep1a_scrollstory_25.jpg",
		altText: "Darkland Season 1",
	},
	{
		src: "/mv-darkland-s1/mobile/704897552-mv_darkland_s1_ep1a_scrollstory_26.jpg",
		altText: "Darkland Season 1",
	},
	{
		src: "/mv-darkland-s1/mobile/704897555-mv_darkland_s1_ep1a_scrollstory_27.jpg",
		altText: "Darkland Season 1",
	},
	{
		src: "/mv-darkland-s1/mobile/704897559-mv_darkland_s1_ep1a_scrollstory_28.jpg",
		altText: "Darkland Season 1",
	},
	{
		src: "/mv-darkland-s1/mobile/704897560-mv_darkland_s1_ep1a_scrollstory_29.jpg",
		altText: "Darkland Season 1",
	},
	{
		src: "/mv-darkland-s1/mobile/704897563-mv_darkland_s1_ep1a_scrollstory_30.jpg",
		altText: "Darkland Season 1",
	},
	{
		src: "/mv-darkland-s1/mobile/704897566-mv_darkland_s1_ep1a_scrollstory_31.jpg",
		altText: "Darkland Season 1",
	},
];
