export type ImageData = {
	src: string;
	altText: string;
};

export const tabletRemindS1Pages: ImageData[] = [
	{
		src: "/mv-remind-s1/tablet/704897031-mv_remind_s1_ep1_2022_tablet_1.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897032-mv_remind_s1_ep1_2022_tablet_2.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897034-mv_remind_s1_ep1_2022_tablet_3.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897038-mv_remind_s1_ep1_2022_tablet_4.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897042-mv_remind_s1_ep1_2022_tablet_5.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897048-mv_remind_s1_ep1_2022_tablet_6.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897052-mv_remind_s1_ep1_2022_tablet_7.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897054-mv_remind_s1_ep1_2022_tablet_8.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897056-mv_remind_s1_ep1_2022_tablet_9.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897060-mv_remind_s1_ep1_2022_tablet_10.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897067-mv_remind_s1_ep1_2022_tablet_11.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897069-mv_remind_s1_ep1_2022_tablet_12.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897070-mv_remind_s1_ep1_2022_tablet_13.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897071-mv_remind_s1_ep1_2022_tablet_14.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897077-mv_remind_s1_ep1_2022_tablet_15.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897079-mv_remind_s1_ep1_2022_tablet_16.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897082-mv_remind_s1_ep1_2022_tablet_17.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897087-mv_remind_s1_ep1_2022_tablet_18.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897089-mv_remind_s1_ep1_2022_tablet_19.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897091-mv_remind_s1_ep1_2022_tablet_20.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897093-mv_remind_s1_ep1_2022_tablet_21.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897097-mv_remind_s1_ep1_2022_tablet_22.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897098-mv_remind_s1_ep1_2022_tablet_23.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897100-mv_remind_s1_ep1_2022_tablet_24.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897109-mv_remind_s1_ep1_2022_tablet_25.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897113-mv_remind_s1_ep1_2022_tablet_26.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897116-mv_remind_s1_ep1_2022_tablet_27.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897119-mv_remind_s1_ep1_2022_tablet_28.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897121-mv_remind_s1_ep1_2022_tablet_29.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897122-mv_remind_s1_ep1_2022_tablet_30.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897125-mv_remind_s1_ep1_2022_tablet_31.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897128-mv_remind_s1_ep1_2022_tablet_32.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897133-mv_remind_s1_ep1_2022_tablet_33.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897135-mv_remind_s1_ep1_2022_tablet_34.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897137-mv_remind_s1_ep1_2022_tablet_35.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897139-mv_remind_s1_ep1_2022_tablet_36.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897141-mv_remind_s1_ep1_2022_tablet_37.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897143-mv_remind_s1_ep1_2022_tablet_38.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897144-mv_remind_s1_ep1_2022_tablet_39.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897148-mv_remind_s1_ep1_2022_tablet_40.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897153-mv_remind_s1_ep1_2022_tablet_41.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897155-mv_remind_s1_ep1_2022_tablet_42.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897163-mv_remind_s1_ep1_2022_tablet_43.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897169-mv_remind_s1_ep1_2022_tablet_44.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897171-mv_remind_s1_ep1_2022_tablet_45.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897172-mv_remind_s1_ep1_2022_tablet_46.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897176-mv_remind_s1_ep1_2022_tablet_47.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897181-mv_remind_s1_ep1_2022_tablet_48.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897187-mv_remind_s1_ep1_2022_tablet_49.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897194-mv_remind_s1_ep1_2022_tablet_50.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897205-mv_remind_s1_ep1_2022_tablet_51.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897212-mv_remind_s1_ep1_2022_tablet_52.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897217-mv_remind_s1_ep1_2022_tablet_53.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897221-mv_remind_s1_ep1_2022_tablet_54.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897224-mv_remind_s1_ep1_2022_tablet_55.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897228-mv_remind_s1_ep1_2022_tablet_56.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897232-mv_remind_s1_ep1_2022_tablet_57.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897235-mv_remind_s1_ep1_2022_tablet_58.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897236-mv_remind_s1_ep1_2022_tablet_59.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897238-mv_remind_s1_ep1_2022_tablet_60.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897240-mv_remind_s1_ep1_2022_tablet_61.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897242-mv_remind_s1_ep1_2022_tablet_62.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897245-mv_remind_s1_ep1_2022_tablet_63.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897249-mv_remind_s1_ep1_2022_tablet_64.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897250-mv_remind_s1_ep1_2022_tablet_65.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897252-mv_remind_s1_ep1_2022_tablet_66.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897260-mv_remind_s1_ep1_2022_tablet_67.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897261-mv_remind_s1_ep1_2022_tablet_68.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897266-mv_remind_s1_ep1_2022_tablet_69.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897270-mv_remind_s1_ep1_2022_tablet_70.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897272-mv_remind_s1_ep1_2022_tablet_71.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897277-mv_remind_s1_ep1_2022_tablet_72.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897281-mv_remind_s1_ep1_2022_tablet_73.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897283-mv_remind_s1_ep1_2022_tablet_74.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897284-mv_remind_s1_ep1_2022_tablet_75.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897287-mv_remind_s1_ep1_2022_tablet_76.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897291-mv_remind_s1_ep1_2022_tablet_77.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897293-mv_remind_s1_ep1_2022_tablet_78.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897294-mv_remind_s1_ep1_2022_tablet_79.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897297-mv_remind_s1_ep1_2022_tablet_80.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897298-mv_remind_s1_ep1_2022_tablet_81.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897300-mv_remind_s1_ep1_2022_tablet_82.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897302-mv_remind_s1_ep1_2022_tablet_83.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/tablet/704897306-mv_remind_s1_ep1_2022_tablet_84.jpg",
		altText: "Re:Mind Season 1",
	},
];
