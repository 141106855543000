export type ImageData = {
	src: string;
	altText: string;
};

export const mobileRemindS1Pages: ImageData[] = [
	{
		src: "/mv-remind-s1/mobile/704896770-mv_remind_2022_ep1_phone_1.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896773-mv_remind_2022_ep1_phone_2.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896777-mv_remind_2022_ep1_phone_3.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896778-mv_remind_2022_ep1_phone_4.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896779-mv_remind_2022_ep1_phone_5.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896781-mv_remind_2022_ep1_phone_6.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896784-mv_remind_2022_ep1_phone_7.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896789-mv_remind_2022_ep1_phone_8.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896792-mv_remind_2022_ep1_phone_9.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896796-mv_remind_2022_ep1_phone_10.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896798-mv_remind_2022_ep1_phone_11.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896801-mv_remind_2022_ep1_phone_12.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896803-mv_remind_2022_ep1_phone_13.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896804-mv_remind_2022_ep1_phone_14.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896805-mv_remind_2022_ep1_phone_15.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896809-mv_remind_2022_ep1_phone_16.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896814-mv_remind_2022_ep1_phone_17.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896816-mv_remind_2022_ep1_phone_18.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896820-mv_remind_2022_ep1_phone_19.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896822-mv_remind_2022_ep1_phone_20.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896824-mv_remind_2022_ep1_phone_21.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896829-mv_remind_2022_ep1_phone_22.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896830-mv_remind_2022_ep1_phone_23.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896832-mv_remind_2022_ep1_phone_24.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896837-mv_remind_2022_ep1_phone_25.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896839-mv_remind_2022_ep1_phone_26.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896845-mv_remind_2022_ep1_phone_27.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896848-mv_remind_2022_ep1_phone_28.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896850-mv_remind_2022_ep1_phone_29.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896851-mv_remind_2022_ep1_phone_30.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896859-mv_remind_2022_ep1_phone_31.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896864-mv_remind_2022_ep1_phone_32.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896866-mv_remind_2022_ep1_phone_33.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896869-mv_remind_2022_ep1_phone_34.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896873-mv_remind_2022_ep1_phone_35.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896875-mv_remind_2022_ep1_phone_36.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896876-mv_remind_2022_ep1_phone_37.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896877-mv_remind_2022_ep1_phone_38.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896880-mv_remind_2022_ep1_phone_39.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896882-mv_remind_2022_ep1_phone_40.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896886-mv_remind_2022_ep1_phone_41.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896890-mv_remind_2022_ep1_phone_42.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896893-mv_remind_2022_ep1_phone_43.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896896-mv_remind_2022_ep1_phone_44.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896901-mv_remind_2022_ep1_phone_45.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896905-mv_remind_2022_ep1_phone_46.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896907-mv_remind_2022_ep1_phone_47.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896911-mv_remind_2022_ep1_phone_48.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896915-mv_remind_2022_ep1_phone_49.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896921-mv_remind_2022_ep1_phone_50.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896923-mv_remind_2022_ep1_phone_51.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896927-mv_remind_2022_ep1_phone_52.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896929-mv_remind_2022_ep1_phone_53.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896931-mv_remind_2022_ep1_phone_54.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896933-mv_remind_2022_ep1_phone_55.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896934-mv_remind_2022_ep1_phone_56.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896935-mv_remind_2022_ep1_phone_57.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896940-mv_remind_2022_ep1_phone_58.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896943-mv_remind_2022_ep1_phone_59.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896946-mv_remind_2022_ep1_phone_60.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896951-mv_remind_2022_ep1_phone_61.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896954-mv_remind_2022_ep1_phone_62.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896957-mv_remind_2022_ep1_phone_63.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896958-mv_remind_2022_ep1_phone_64.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896962-mv_remind_2022_ep1_phone_65.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896963-mv_remind_2022_ep1_phone_66.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896967-mv_remind_2022_ep1_phone_67.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896970-mv_remind_2022_ep1_phone_68.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896972-mv_remind_2022_ep1_phone_69.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896977-mv_remind_2022_ep1_phone_70.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896979-mv_remind_2022_ep1_phone_71.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896983-mv_remind_2022_ep1_phone_72.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896984-mv_remind_2022_ep1_phone_73.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896987-mv_remind_2022_ep1_phone_74.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896989-mv_remind_2022_ep1_phone_75.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896991-mv_remind_2022_ep1_phone_76.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896993-mv_remind_2022_ep1_phone_77.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704896996-mv_remind_2022_ep1_phone_78.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704897001-mv_remind_2022_ep1_phone_79.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704897006-mv_remind_2022_ep1_phone_80.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704897010-mv_remind_2022_ep1_phone_81.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704897014-mv_remind_2022_ep1_phone_82.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704897015-mv_remind_2022_ep1_phone_83.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704897018-mv_remind_2022_ep1_phone_84.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704897021-mv_remind_2022_ep1_phone_85.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704897024-mv_remind_2022_ep1_phone_86.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704897025-mv_remind_2022_ep1_phone_87.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704897026-mv_remind_2022_ep1_phone_88.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704897029-mv_remind_2022_ep1_phone_89.jpg",
		altText: "Re:Mind Season 1",
	},
	{
		src: "/mv-remind-s1/mobile/704897030-mv_remind_2022_ep1_phone_90.jpg",
		altText: "Re:Mind Season 1",
	},
];
