import {
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuSeparator,
} from "@/components/core/actions/dropdown-menu.tsx";
import { useModal } from "@/components/core/modals/use-modal.ts";
import { IconShare } from "@/components/mvicons";
import ShareComicsModal from "@/components/share-comics-modal";

export default function ReaderDropdownItems() {
	const modal = useModal();

	return (
		<DropdownMenuGroup>
			<DropdownMenuItem
				onSelect={() => {
					modal.open({
						title: "",
						className: "overflow-hidden",
						component: <ShareComicsModal />,
					});
				}}
			>
				<div className="flex flex-row items-center gap-2 py-1">
					<IconShare size={16} />
					<span className="translate-y-[-1px] leading-1">Share Comics</span>
				</div>
			</DropdownMenuItem>
			{/*<DropdownMenuSeparator />
			<DropdownMenuItem>
				<div className="flex flex-row items-center gap-2 py-1">
					<IconShare size={16} />
					<span className="translate-y-[-1px] leading-1">Menu Option 2</span>
				</div>
			</DropdownMenuItem>
			<DropdownMenuSeparator />
			<DropdownMenuItem>
				<div className="flex flex-row items-center gap-2 py-1">
					<IconShare size={16} />
					<span className="translate-y-[-1px] leading-1">Menu Option 3</span>
				</div>
			</DropdownMenuItem>*/}
		</DropdownMenuGroup>
	);
}
