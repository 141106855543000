import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuTrigger,
} from "@/components/core/actions/dropdown-menu.tsx";
import {
	IconDotsHor,
	IconMaximize,
	IconMinimize,
	IconScroll,
	IconSwipe,
	IconTap,
} from "@/components/mvicons";
import ReaderDropdownItems from "@/components/reader/reader-dropdown-items.tsx";
import { IconX } from "@tabler/icons-react";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import ReactDOM from "react-dom";
import ScrollReader from "./scroll-reader";
import SwipeReader from "./swipe-reader";
import TapReader from "./tap-reader";

const Reader = ({ isOpen, onClose }) => {
	const [isActive, setIsActive] = useState(false);
	const [isControlsHidden, setControlsHidden] = useState(false);
	const [activeMode, setActiveMode] = useState("tap"); // Default mode is 'tap'

	const handleButtonClick = (mode) => {
		setActiveMode(mode);
	};

	const renderContent = () => {
		switch (activeMode) {
			case "tap":
				return (
					<div className="flex flex-col justify-center items-center h-full w-auto">
						<TapReader />
					</div>
				);
			case "scroll":
				return (
					<div className="flex flex-col justify-center items-center h-full w-auto">
						<ScrollReader />
					</div>
				);
			case "swipe":
				return (
					<div className="flex flex-col justify-center items-center h-full w-auto">
						<SwipeReader />
					</div>
				);
		}
	};

	return ReactDOM.createPortal(
		<AnimatePresence>
			{isOpen && (
				<motion.div
					className={clsx(
						"z-50 fixed right-0 bottom-0 left-0 top-0 flex items-center justify-center min-h-screen w-full",
					)}
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.1 }}
				>
					{/* reader ui overlay */}
					<motion.div
						className="absolute inset-0 h-full w-full bg-black"
						initial={{ opacity: 0, backdropFilter: "blur(0px)" }}
						animate={{ opacity: 1, backdropFilter: "blur(3px)" }}
						exit={{ opacity: 0, backdropFilter: "blur(0px)" }}
						transition={{ duration: 0.1 }}
					/>

					{/* reader ui container */}
					<motion.div
						className="relative flex flex-col justify-center items-center h-full w-full"
						initial={{ opacity: 0, scale: 0.95 }}
						animate={{ opacity: 1, scale: 1 }}
						exit={{ opacity: 0, scale: 0.95 }}
						transition={{ duration: 0.1 }}
					>
						{/* reader topbar */}
						<motion.div
							initial={{ y: 0 }}
							animate={{ y: isControlsHidden ? "-100%" : 0 }}
							transition={{ duration: 0.25, ease: "easeInOut" }}
							className="z-10 fixed top-0 left-0 right-0 flex flex-row items-center justify-between w-full h-[100px] px-6 gap-4 text-white"
						>
							{/* close button */}
							<button
								onClick={() => {
									onClose();
									document.body.style.overflow = "";
								}}
								type="button"
								className="inline-flex justify-center items-center w-[40px] h-[40px] rounded-full outline-none border-none ring-0 bg-white/20 hover:bg-white/30 backdrop-blur text-white"
							>
								<IconX size={28} stroke={2} />
							</button>
							{/* reader menu */}
							<DropdownMenu
								modal={false}
								onOpenChange={(open) => setIsActive(open)}
							>
								<DropdownMenuTrigger asChild>
									<button
										type="button"
										className={clsx(
											"inline-flex flex-col justify-center items-center w-[38px] h-[38px] rounded-xl ",
											{
												"bg-white text-mvdark-950": isActive,
												"bg-white/20 hover:bg-white/30 text-white": !isActive,
											},
										)}
									>
										<IconDotsHor size={18} />
									</button>
								</DropdownMenuTrigger>
								<DropdownMenuContent sideOffset={-12} alignOffset={0}>
									<ReaderDropdownItems />
								</DropdownMenuContent>
							</DropdownMenu>
						</motion.div>

						{/* reader bottombar */}
						<motion.div
							initial={{ y: 0 }}
							animate={{ y: isControlsHidden ? "100%" : 0 }}
							transition={{ duration: 0.25, ease: "easeInOut" }}
							className="z-10 fixed bottom-0 left-0 right-0 flex flex-row justify-center w-full h-[100px] px-6 gap-4"
						>
							{/* reader types */}
							<div className="inline-flex flex-row justify-center items-center w-auto gap-8">
								<button
									type="button"
									className={`inline-flex flex-col items-center justify-center gap-2 text-white ${activeMode === "tap" ? "opacity-100" : "opacity-50"} hover:opacity-100`}
									onClick={() => handleButtonClick("tap")}
								>
									<IconTap size={24} />
									<span className="text-[10px] uppercase tracking-widest">
										Tap
									</span>
								</button>
								<button
									type="button"
									className={`inline-flex flex-col items-center justify-center gap-2 text-white ${activeMode === "scroll" ? "opacity-100" : "opacity-50"} hover:opacity-100`}
									onClick={() => handleButtonClick("scroll")}
								>
									<IconScroll size={24} />
									<span className="text-[10px] uppercase tracking-widest">
										Scroll
									</span>
								</button>
								<button
									type="button"
									className={`inline-flex flex-col items-center justify-center gap-2 text-white ${activeMode === "swipe" ? "opacity-100" : "opacity-50"} hover:opacity-100`}
									onClick={() => handleButtonClick("swipe")}
								>
									<IconSwipe size={24} />
									<span className="text-[10px] uppercase tracking-widest">
										Swipe
									</span>
								</button>
							</div>
						</motion.div>

						{/* screen mode button */}
						<div className="z-10 fixed bottom-6 right-6 inline-flex flex-row justify-end items-end">
							<button
								onClick={() => setControlsHidden(!isControlsHidden)}
								type="button"
								className="inline-flex justify-center items-center w-[40px] h-[40px] rounded-full outline-none border-none ring-0 bg-white/20 hover:bg-white/30 backdrop-blur text-white"
							>
								{isControlsHidden ? (
									<IconMinimize size={18} />
								) : (
									<IconMaximize size={18} />
								)}
							</button>
						</div>

						{/* main reader contents */}
						<motion.div
							className="relative flex flex-col items-center gap-8 w-full bg-black"
							animate={{
								height: isControlsHidden ? "100vh" : "calc(100vh - 200px)",
							}}
							transition={{ duration: 0.3, ease: "easeInOut" }}
						>
							<motion.div className="relative flex flex-col justify-center items-center h-screen w-screen overflow-hidden">
								{renderContent()}
							</motion.div>
						</motion.div>
					</motion.div>
				</motion.div>
			)}
		</AnimatePresence>,
		document.body,
	);
};

export default Reader;
