import {
	Button,
	ButtonIcon,
	ButtonLabel,
} from "@/components/core/buttons/button";
import { useModalControl } from "@/components/core/modals/use-modal-controller.ts";
import { IconInstagram, IconPhantom, IconTwitterx } from "@/components/mvicons";
import { IconBrandFacebook } from "@tabler/icons-react";

export default function ShareComicsModal() {
	const { close } = useModalControl();

	return (
		<div className="flex flex-col justify-center items-center w-full">
			<div className="flex flex-col w-full max-w-[420px] gap-8">
				<div className="flex flex-col justify-center items-center w-full gap-8">
					<div className="flex flex-col justify-center items-center w-full">
						<span className="font-quagmire font-bold text-2xl text-white uppercase leading-8">
							Share This Series
						</span>
						<span className="font-quagmire font-bold text-2xl text-mvred-600 uppercase  leading-8">
							Let us all Enjoy!
						</span>
					</div>

					<div className="flex flex-col w-full gap-8">
						<div className="flex flex-col w-full gap-2">
							<Button
								onClick={() => {
									alert("Shared!");
								}}
								className="bg-mvdark-950 w-full !gap-2"
								type="button"
								variant="default"
								size="md"
							>
								<ButtonIcon>
									<IconTwitterx size={24} />
								</ButtonIcon>
								<ButtonLabel>Share on Twitter/X</ButtonLabel>
							</Button>
							<Button
								onClick={() => {
									alert("Shared!");
								}}
								className="bg-mvdark-950 w-full !gap-2"
								type="button"
								variant="default"
								size="md"
							>
								<ButtonIcon>
									<IconInstagram size={24} />
								</ButtonIcon>
								<ButtonLabel>Share on Instagram</ButtonLabel>
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
