export type ImageData = {
	src: string;
	altText: string;
};

export const tabletCocS19Pages: ImageData[] = [
	{
		src: "/mv-chamber-of-chills-s19/tablet/MV_AE_PreCodeHorror_ChamberofChills19_Cover_Ashcan_Cvr3.jpg",
		altText: "Chamber of Chills - Season 19",
	},
	{
		src: "/mv-chamber-of-chills-s19/tablet/MV_AE_PreCodeHorror_ChamberofChills19_Ashcan_010824_01.jpg",
		altText: "Chamber of Chills - Season 19",
	},
	{
		src: "/mv-chamber-of-chills-s19/tablet/MV_AE_PreCodeHorror_ChamberofChills19_Ashcan_010824_02.jpg",
		altText: "Chamber of Chills - Season 19",
	},
	{
		src: "/mv-chamber-of-chills-s19/tablet/MV_AE_PreCodeHorror_ChamberofChills19_Ashcan_010824_03.jpg",
		altText: "Chamber of Chills - Season 19",
	},
	{
		src: "/mv-chamber-of-chills-s19/tablet/MV_AE_PreCodeHorror_ChamberofChills19_Ashcan_010824_04.jpg",
		altText: "Chamber of Chills - Season 19",
	},
	{
		src: "/mv-chamber-of-chills-s19/tablet/MV_AE_PreCodeHorror_ChamberofChills19_Ashcan_010824_05.jpg",
		altText: "Chamber of Chills - Season 19",
	},
	{
		src: "/mv-chamber-of-chills-s19/tablet/MV_AE_PreCodeHorror_ChamberofChills19_Ashcan_010824_06.jpg",
		altText: "Chamber of Chills - Season 19",
	},
	{
		src: "/mv-chamber-of-chills-s19/tablet/MV_AE_PreCodeHorror_ChamberofChills19_Ashcan_010824_07.jpg",
		altText: "Chamber of Chills - Season 19",
	},
	{
		src: "/mv-chamber-of-chills-s19/tablet/MV_AE_PreCodeHorror_ChamberofChills19_Ashcan_010824_08.jpg",
		altText: "Chamber of Chills - Season 19",
	},
	{
		src: "/mv-chamber-of-chills-s19/tablet/MV_AE_PreCodeHorror_ChamberofChills19_Ashcan_010824_09.jpg",
		altText: "Chamber of Chills - Season 19",
	},
	{
		src: "/mv-chamber-of-chills-s19/tablet/MV_AE_PreCodeHorror_ChamberofChills19_Ashcan_010824_10.jpg",
		altText: "Chamber of Chills - Season 19",
	},
	{
		src: "/mv-chamber-of-chills-s19/tablet/MV_AE_PreCodeHorror_ChamberofChills19_Ashcan_010824_11.jpg",
		altText: "Chamber of Chills - Season 19",
	},
	{
		src: "/mv-chamber-of-chills-s19/tablet/MV_AE_PreCodeHorror_ChamberofChills19_Ashcan_010824_12.jpg",
		altText: "Chamber of Chills - Season 19",
	},
	{
		src: "/mv-chamber-of-chills-s19/tablet/MV_AE_PreCodeHorror_ChamberofChills19_Ashcan_010824_13.jpg",
		altText: "Chamber of Chills - Season 19",
	},
	{
		src: "/mv-chamber-of-chills-s19/tablet/MV_AE_PreCodeHorror_ChamberofChills19_Ashcan_010824_14.jpg",
		altText: "Chamber of Chills - Season 19",
	},
	{
		src: "/mv-chamber-of-chills-s19/tablet/MV_AE_PreCodeHorror_ChamberofChills19_Ashcan_010824_15.jpg",
		altText: "Chamber of Chills - Season 19",
	},
	{
		src: "/mv-chamber-of-chills-s19/tablet/MV_AE_PreCodeHorror_ChamberofChills19_Ashcan_010824_16.jpg",
		altText: "Chamber of Chills - Season 19",
	},
	{
		src: "/mv-chamber-of-chills-s19/tablet/MV_AE_PreCodeHorror_ChamberofChills19_Ashcan_010824_17.jpg",
		altText: "Chamber of Chills - Season 19",
	},
	{
		src: "/mv-chamber-of-chills-s19/tablet/MV_AE_PreCodeHorror_ChamberofChills19_Ashcan_010824_18.jpg",
		altText: "Chamber of Chills - Season 19",
	},
	{
		src: "/mv-chamber-of-chills-s19/tablet/MV_AE_PreCodeHorror_ChamberofChills19_Ashcan_010824_19.jpg",
		altText: "Chamber of Chills - Season 19",
	},
	{
		src: "/mv-chamber-of-chills-s19/tablet/MV_AE_PreCodeHorror_ChamberofChills19_Ashcan_010824_20.jpg",
		altText: "Chamber of Chills - Season 19",
	},
	{
		src: "/mv-chamber-of-chills-s19/tablet/MV_AE_PreCodeHorror_ChamberofChills19_Ashcan_010824_21.jpg",
		altText: "Chamber of Chills - Season 19",
	},
	{
		src: "/mv-chamber-of-chills-s19/tablet/MV_AE_PreCodeHorror_ChamberofChills19_Ashcan_010824_22.jpg",
		altText: "Chamber of Chills - Season 19",
	},
	{
		src: "/mv-chamber-of-chills-s19/tablet/MV_AE_PreCodeHorror_ChamberofChills19_Ashcan_010824_23.jpg",
		altText: "Chamber of Chills - Season 19",
	},
	{
		src: "/mv-chamber-of-chills-s19/tablet/MV_AE_PreCodeHorror_ChamberofChills19_Ashcan_010824_24.jpg",
		altText: "Chamber of Chills - Season 19",
	},
	{
		src: "/mv-chamber-of-chills-s19/tablet/MV_AE_PreCodeHorror_ChamberofChills19_Ashcan_010824_25.jpg",
		altText: "Chamber of Chills - Season 19",
	},
	{
		src: "/mv-chamber-of-chills-s19/tablet/MV_AE_PreCodeHorror_ChamberofChills19_Ashcan_010824_26.jpg",
		altText: "Chamber of Chills - Season 19",
	},
	{
		src: "/mv-chamber-of-chills-s19/tablet/MV_AE_PreCodeHorror_ChamberofChills19_Ashcan_010824_27.jpg",
		altText: "Chamber of Chills - Season 19",
	},
	{
		src: "/mv-chamber-of-chills-s19/tablet/MV_AE_PreCodeHorror_ChamberofChills19_Ashcan_010824_28.jpg",
		altText: "Chamber of Chills - Season 19",
	},
	{
		src: "/mv-chamber-of-chills-s19/tablet/MV_AE_PreCodeHorror_ChamberofChills19_Ashcan_010824_29.jpg",
		altText: "Chamber of Chills - Season 19",
	},
	{
		src: "/mv-chamber-of-chills-s19/tablet/MV_AE_PreCodeHorror_ChamberofChills19_Ashcan_010824_30.jpg",
		altText: "Chamber of Chills - Season 19",
	},
	{
		src: "/mv-chamber-of-chills-s19/tablet/MV_AE_PreCodeHorror_ChamberofChills19_Ashcan_010824_31.jpg",
		altText: "Chamber of Chills - Season 19",
	},
	{
		src: "/mv-chamber-of-chills-s19/tablet/MV_AE_PreCodeHorror_ChamberofChills19_Ashcan_010824_32.jpg",
		altText: "Chamber of Chills - Season 19",
	},
	{
		src: "/mv-chamber-of-chills-s19/tablet/MV_AE_PreCodeHorror_ChamberofChills19_Cover_Ashcan_Cvr2.jpg",
		altText: "Chamber of Chills - Season 19",
	},
	{
		src: "/mv-chamber-of-chills-s19/tablet/MV_AE_PreCodeHorror_ChamberofChills19_Cover_Ashcan_Cvr4.jpg",
		altText: "Chamber of Chills - Season 19",
	},
];
