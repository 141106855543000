import { useSupplyClaimMutation } from "@/api/queries/supply-queries.ts";
import { Button, ButtonLabel } from "@/components/core/buttons/button";
import { useModal } from "@/components/core/modals/use-modal.ts";
import {
	IconCheckCircle,
	IconComic,
	IconExp,
	IconQrcode,
} from "@/components/mvicons";
import ScanProgress from "@/components/scan-progress.tsx";
import { ErrorCameraPermissionModal } from "@/components/scanner/error-camera-permission-modal.tsx";
import { ErrorClaimedModal } from "@/components/scanner/error-claimed-modal.tsx";
import { ErrorInvalidCodeModal } from "@/components/scanner/error-invalid-code-modal.tsx";
import { RequestPermissionModal } from "@/components/scanner/request-permission-modal.tsx";
import type { Issue } from "@/types/issue.ts";
import { getAssetByCollectionName } from "@/utils/asset-url.ts";
import { formatDate } from "@/utils/format-date.ts";
import { IconX } from "@tabler/icons-react";
import { Scanner } from "@yudiel/react-qr-scanner";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { type ReactNode, useEffect, useState } from "react";
import ReactDOM from "react-dom";

type ErrorResponse = {
	response: {
		data: {
			errors: {
				code: string;
			};
		};
	};
};

function Scan({
	isOpen,
	isAuth,
	onClose,
}: {
	isOpen: boolean;
	isAuth?: boolean;
	onClose: () => void;
}) {
	const modal = useModal();
	const supplyClaimMutation = useSupplyClaimMutation();
	const [permissionGranted, setPermissionGranted] = useState(false);
	const [animationEnded, setAnimationEnded] = useState(false);
	const [issue, setIssue] = useState<Issue | null>();
	const [step, setStep] = useState(isAuth ? 2 : 1);
	const [scannerPaused, setScannerPaused] = useState(false);
	const [isMediumScreen, setIsMediumScreen] = useState(false);

	useEffect(() => {
		const mediaQuery = window.matchMedia("(min-width: 768px)"); // Tailwind's 'md' breakpoint
		setIsMediumScreen(mediaQuery.matches);

		const handleResize = () => {
			setIsMediumScreen(mediaQuery.matches);
		};

		mediaQuery.addEventListener("change", handleResize);

		return () => {
			mediaQuery.removeEventListener("change", handleResize);
		};
	}, []);

	useEffect(() => {
		checkCameraPermission();
	}, []);

	const handleResetState = () => {
		setIssue(null);
		setStep(2);
		setAnimationEnded(true);
	};

	const checkCameraPermission = async () => {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({ video: true });

			if (stream) {
				setPermissionGranted(true);
			}
		} catch (error) {
			console.error("Failed to check camera permissions:", error);
		}
	};

	const requestCameraPermissions = async () => {
		try {
			await navigator.mediaDevices.getUserMedia({ video: true });
		} catch (error) {
			modal.open({
				type: "confirm",
				onConfirm: async () => {},
				children: <ErrorCameraPermissionModal />,
			});
		}
	};

	const handleScan = async (
		results: { rawValue: string; format: string }[],
	) => {
		if (results?.length && !scannerPaused) {
			const scannedCode = results[0];

			if (scannedCode.format === "unknown") {
				return;
			}
			setScannerPaused(true);

			try {
				const response = await supplyClaimMutation.mutateAsync({
					code: scannedCode.rawValue,
				});

				setIssue(response.issue);
			} catch (error) {
				const typedError = error as ErrorResponse;
				const errCode = typedError.response.data.errors.code;

				let modalComponent:
					| ((handleConfirm: () => void) => ReactNode)
					| undefined;

				switch (errCode) {
					case "user_redeemed":
						modalComponent = (handleConfirm: () => void) => (
							<ErrorClaimedModal handleConfirm={handleConfirm} />
						);
						break;
					case "invalid_qr":
						modalComponent = (handleConfirm: () => void) => (
							<ErrorInvalidCodeModal handleConfirm={handleConfirm} />
						);
						break;
					default:
						return;
				}

				modal.open({
					type: "confirm",
					onConfirm: async () => {
						setScannerPaused(false);
					},
					children: (handleConfirm: () => void) =>
						modalComponent ? modalComponent(handleConfirm) : null,
					onClose: () => setScannerPaused(false),
				});
			}
		}
	};

	const handleError = () => {
		modal.open({
			type: "confirm",
			onConfirm: async () => {},
			children: (handleConfirm) => (
				<ErrorInvalidCodeModal handleConfirm={handleConfirm} />
			),
		});
	};

	const handleRequestCameraPermission = () => {
		if (permissionGranted) {
			setStep(3);
			return;
		}
		modal.open({
			type: "confirm",
			onConfirm: async () => {
				await requestCameraPermissions();
			},
			children: (handleConfirm) => (
				<RequestPermissionModal handleConfirm={handleConfirm} />
			),
		});
	};

	const customClassNames = {
		container: "scanner",
		video: "object-cover",
		finderBorder: 0,
	};

	const isProduction = import.meta.env.VITE_PUBLIC_ENV === "production";

	return ReactDOM.createPortal(
		<AnimatePresence>
			{isOpen && (
				<motion.div
					className={clsx(
						"z-50 fixed right-0 bottom-0 left-0 flex items-center justify-center",
						isProduction
							? "top-0 min-h-[100svh]"
							: "top-8 min-h-[calc(100svh-2rem)]",
					)}
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.1 }}
				>
					{/* scan ui overlay */}
					<motion.div
						className="absolute inset-0 bg-mvdark-950/60"
						initial={{ opacity: 0, backdropFilter: "blur(0px)" }}
						animate={{ opacity: 1, backdropFilter: "blur(3px)" }}
						exit={{ opacity: 0, backdropFilter: "blur(0px)" }}
						transition={{ duration: 0.1 }}
					/>

					{/* scan ui container */}
					<motion.div
						className="font-aller relative flex flex-col h-auto w-auto"
						initial={{ opacity: 0, scale: 0.95 }}
						animate={{ opacity: 1, scale: 1 }}
						exit={{ opacity: 0, scale: 0.95 }}
						transition={{ duration: 0.1 }}
					>
						{/* scan ui close button */}
						{step !== 4 && (
							<button
								onClick={() => {
									setStep(1);
									onClose();
								}}
								type="button"
								className="z-10 absolute left-4 top-4 inline-flex justify-center items-center w-[40px] h-[40px] rounded-full outline-none border-none ring-0 bg-white/20 hover:bg-white/30 backdrop-blur text-white"
							>
								<IconX size={28} stroke={2} />
							</button>
						)}

						{/* scan ui contents */}
						<div className="relative flex flex-col items-center gap-8">
							{step === 1 && (
								<motion.div className="relative flex flex-col justify-center items-center h-[100svh] md:h-[720px] w-screen md:w-[520px] rounded-none md:rounded-4xl border border-white/10 bg-mvdark-950 overflow-hidden">
									<div className="z-[3] flex flex-col justify-center items-center w-full h-full p-8">
										<div className="inline-flex w-[72px] mb-16">
											<img
												src="/svg/macroverse-logo-white.svg"
												alt="Macroverse"
											/>
										</div>
										<div className="flex flex-col justify-center items-center w-full max-w-[420px] mx-auto p-4 gap-6">
											<div className="inline-flex flex-col justify-center items-center w-auto gap-2">
												<div className="inline-flex w-[300px]">
													<img
														className="block w-full"
														src="/svg/authentic-editions.svg"
														alt="Macroverse"
													/>
												</div>
												<div className="inline-flex w-[90px]">
													<img
														className="block w-full"
														src="/svg/indicator-comics-scan.svg"
														alt="Macroverse"
													/>
												</div>
											</div>
											<span className="text-center font-semibold text-md text-white">
												Scan Authentic Edition QR Codes and redeem your free
												Digital Twins plus Bonus Perks and Rewards!
											</span>
											<Button
												onClick={() => setStep(2)}
												className="font-quagmire w-full uppercase"
												type="button"
												variant="default"
												color="white"
												size="lg"
											>
												<ButtonLabel>Login and Scan</ButtonLabel>
											</Button>
										</div>
									</div>
									<div className="z-[2] absolute w-[380px] h-[380px] rounded-full blur-[100px] bg-black opacity-50" />
									<div className="z-[1] absolute scanner-modal-container inset-0 blur-[40px]" />
								</motion.div>
							)}

							{step === 2 && (
								<motion.div className="relative flex flex-col justify-center items-center h-[100svh] md:h-[720px] w-screen md:w-[520px] rounded-none md:rounded-4xl border border-white/10 bg-mvdark-950 overflow-hidden">
									<div className="z-[3] flex flex-1 flex-col justify-center items-center w-full max-w-[420px] mx-auto p-4 gap-4">
										<div className="inline-flex flex-row items-center gap-2">
											<IconQrcode className="text-white" size={32} />
											<span className="text-white text-xl">Scan QR Code</span>
										</div>
										<div className="inline-flex flex-col justiy-center items-center">
											<span className="font-quagmire text-xl font-bold text-white uppercase leading-7">
												Get your Digial Twin
											</span>
											<span className="font-quagmire text-xl font-bold text-mvred-600 uppercase leading-7">
												+ Bonus Rewards
											</span>
										</div>
										<span className="text-center text-sm text-white">
											Each printed issue of Authentic Editions include a QR code
											connected to a unique Digital Twin.
										</span>
										<span className="text-center text-sm text-white">
											Scan and claim this unique Digital Edition and add it to
											your collection. The physical copy is paired with the
											digital to create a bonded set.{" "}
										</span>
										<span className="text-center text-sm text-white">
											Only the owner of the physical can claim it and any
											additional perks and rewards that come with it.
										</span>
										<span className="text-center text-sm text-white">
											Let’s get started!
										</span>
										<div className="flex flex-col w-full mt-2 gap-2">
											<Button
												onClick={handleRequestCameraPermission}
												className="font-quagmire w-full backdrop-blur uppercase"
												type="button"
												variant="gradient"
												size="md"
											>
												<ButtonLabel>Scan Now</ButtonLabel>
											</Button>

											<Button
												onClick={() => setStep(3)}
												className="font-quagmire w-full backdrop-blur uppercase"
												type="button"
												variant="outline"
												size="md"
												color="blue"
											>
												<ButtonLabel>Not Now</ButtonLabel>
											</Button>
										</div>
									</div>
									<div className="z-[2] absolute w-[380px] h-[380px] rounded-full blur-[100px] bg-black opacity-50" />
									<div className="z-[1] absolute scanner-modal-container inset-0 blur-[40px]" />
								</motion.div>
							)}

							{step === 3 && (
								<motion.div className="relative flex flex-col justify-center items-center h-[100svh] md:h-[720px] w-screen md:w-[520px] rounded-none md:rounded-4xl border border-white/10 bg-mvdark-950 overflow-hidden">
									{/* camera state overlay */}
									<div className="z-20 absolute top-12 left-1/2 -translate-x-1/2">
										<div className="flex flex-row items-center w-auto gap-2 bg-black/30 backdrop-blur py-2 px-4 rounded-full">
											<div className="inline-flex w-[14px] h-[14px] rounded-full bg-mvred-600">
												<div className="animate-ping inline-flex w-[14px] h-[14px] rounded-full bg-mvred-600" />
											</div>
											<span className="tracking-wider text-xs text-white uppercase">
												Camera is Active
											</span>
										</div>
									</div>

									{/* scan progress overlay */}
									<div className="z-[2] absolute bottom-12 flex flex-row justify-center w-full ">
										<div className="flex flex-row items-center w-auto p-6 gap-3 rounded-lg bg-black/30 backdrop-blur">
											{issue ? (
												<ScanProgress
													cover={getAssetByCollectionName(
														issue?.assets,
														"cover_image",
													)}
													baseDelay={2000}
													baseDuration={5000}
													onAnimationEnd={() => {
														setAnimationEnded(true);

														// This gives an extra time before loading the last sscreen
														setTimeout(() => {
															setStep(4);
														}, 5000);
													}}
												/>
											) : (
												<div className="relative inline-flex justify-center items-center min-h-[80px] w-[56px]">
													<div className="z-[2] inline-flex text-white">
														<IconComic size={54} />
													</div>
												</div>
											)}

											{animationEnded ? (
												<div className="flex flex-col gap-2">
													<div className="flex flex-row items-center gap-1">
														<IconQrcode className="text-mvblue-300" size={14} />
														<span className="text-xs text-mvblue-300">
															Issue Confirmed
														</span>
													</div>
													<div className="flex flex-col">
														<span className="text-xs text-white leading-tight">
															{issue?.name} #{issue?.issueNumber}
														</span>
														<span className="text-xs text-white leading-tight">
															Authentic Edition
														</span>
														<span className="text-xs text-white leading-tight">
															{issue?.releasedAt}
														</span>
													</div>
												</div>
											) : (
												<div className="flex flex-col gap-2">
													<div className="flex flex-row items-center gap-1">
														<IconQrcode className="text-mvblue-300" size={14} />
														<span className="text-xs text-mvblue-300">
															Identifying Issue...
														</span>
													</div>
													<div className="flex flex-col">
														<span className="text-xs text-white leading-tight">
															Finding Title...
														</span>
														<span className="text-xs text-white leading-tight">
															Locating Edition...
														</span>
														<span className="text-xs text-white leading-tight">
															Confirming Details...
														</span>
													</div>
												</div>
											)}
										</div>
									</div>

									<Scanner
										paused={!!issue}
										onScan={handleScan}
										onError={handleError}
										allowMultiple={false}
										scanDelay={2000}
										classNames={customClassNames}
									/>

									<div className="z-[2] absolute w-[380px] h-[380px] rounded-full blur-[100px] bg-black opacity-50" />
									<div
										className={clsx(
											"z-[1] absolute scanner-modal-container",
											animationEnded
												? "inset-0 blur-[40px]"
												: "scanner-modal-container--fast inset-0 blur-[10px]",
										)}
									/>
								</motion.div>
							)}

							{step === 4 && (
								<motion.div className="relative flex justify-center items-center w-screen h-[100svh]">
									<div className="z-[4] relative flex flex-col justify-center items-center h-[100svh] md:h-auto w-screen md:w-[720px] py-16 px-8 overflow-hidden">
										<button
											onClick={() => {
												setStep(1);
												onClose();
											}}
											type="button"
											className="z-10 absolute left-4 top-4 inline-flex justify-center items-center w-[40px] h-[40px] rounded-full outline-none border-none ring-0 bg-white/20 hover:bg-white/30 backdrop-blur text-white"
										>
											<IconX size={28} stroke={2} />
										</button>
										<motion.div
											className="flex flex-row w-full max-w-[320px] md:w-[580px] md:max-w-none gap-4"
											initial={isMediumScreen ? { width: 240 } : { width: 580 }}
											animate={isMediumScreen ? { width: 580 } : { width: 580 }}
											exit={isMediumScreen ? { width: 240 } : { width: 580 }}
											transition={{ delay: 1, duration: 0.3 }}
										>
											<div className="relative hidden md:inline-flex shrink-0 w-[240px] border-2 border-white">
												<img
													className="block w-full"
													src={getAssetByCollectionName(
														issue?.assets,
														"cover_image",
													)}
													alt="Macroverse"
												/>
												<motion.div
													className="z-[-1] absolute inset-0 rounded-full bg-mvmain-gradient blur-2xl"
													initial={{ opacity: 0 }}
													animate={{ opacity: 1 }}
													transition={{
														duration: 1,
														delay: 1,
														ease: "easeInOut",
													}}
													style={{
														animation: "spinAndScale 4s infinite linear",
													}}
												/>
											</div>
											<motion.div
												className="flex flex-col items-center grow gap-4"
												initial={
													isMediumScreen ? { opacity: 0 } : { opacity: 1 }
												}
												animate={
													isMediumScreen ? { opacity: 1 } : { opacity: 1 }
												}
												exit={isMediumScreen ? { opacity: 0 } : { opacity: 1 }}
												transition={{ delay: 1.3, duration: 0.3 }}
											>
												<div className="inline-flex w-[120px]">
													<img
														className="block w-full"
														src="/svg/macroverse-authentic-editions.svg"
														alt="Macroverse"
													/>
												</div>
												<div className="relative inline-flex md:hidden shrink-0 w-[140px] border-2 border-white">
													<img
														className="block w-full"
														src={getAssetByCollectionName(
															issue.assets,
															"cover_image",
														)}
														alt="Macroverse"
													/>
													<motion.div
														className="z-[-1] absolute inset-0 rounded-full bg-mvmain-gradient blur-2xl"
														initial={{ opacity: 0 }}
														animate={{ opacity: 1 }}
														transition={{
															duration: 1,
															delay: 1,
															ease: "easeInOut",
														}}
														style={{
															animation: "spinAndScale 4s infinite linear",
														}}
													/>
												</div>
												<div className="flex flex-row justify-center items-center w-full p-3 gap-2 rounded-lg bg-mvblue-300/10 backdrop-blur">
													<div className="flex justify-center items-center flex-row shrink-0 w-[44px] h-[44px] rounded-sm bg-white">
														<IconQrcode className="text-black" size={36} />
													</div>
													<div className="flex flex-col gap-1">
														<div className="flex flex-row items-center gap-1">
															<IconCheckCircle
																className="text-mvblue-300"
																size={16}
															/>
															<span className="text-xs text-mvblue-300">
																Digital Twin Paired
															</span>
														</div>
														<div className="flex flex-row items-center gap-1 p-1 px-2 rounded-md bg-white/10">
															<span className="text-xs text-white leading-tight">
																#000001
															</span>
														</div>
													</div>
												</div>
												<div className="flex flex-row items-center w-auto gap-2">
													<div className="inline-flex flex-col justify-center items-center p-1.5 rounded-md bg-white/10 text-white">
														<IconExp size={22} />
														<span className="font-semibold text-2xs leading-tight">
															+10
														</span>
													</div>
													<div className="flex flex-col">
														<span className="font-semibold text-xs text-white leading-tight">
															{issue?.name} #{issue?.issueNumber}
														</span>
														<span className="text-xs text-white leading-tight">
															Authentic Edition
														</span>
														<span className="text-xs text-white/50 leading-tight">
															{formatDate(issue?.releasedAt)}
														</span>
													</div>
												</div>
												<div className="flex flex-col w-full mt-2 gap-2">
													<div className="flex flex-row gap-2">
														<Button
															disabled
															className="flex-1 font-quagmire backdrop-blur uppercase"
															type="button"
															variant="outline"
															size="md"
															color="blue"
														>
															<ButtonLabel>Secure</ButtonLabel>
														</Button>
														<Button
															disabled
															className="flex-1 font-quagmire backdrop-blur uppercase"
															type="button"
															variant="outline"
															size="md"
															color="blue"
														>
															<ButtonLabel>Read</ButtonLabel>
														</Button>
													</div>
													<Button
														className="font-quagmire w-full backdrop-blur uppercase"
														type="button"
														variant="outline"
														size="md"
														color="blue"
													>
														<ButtonLabel>Share</ButtonLabel>
													</Button>
													<Button
														onClick={handleResetState}
														className="font-quagmire w-full backdrop-blur uppercase"
														type="button"
														variant="gradient"
														size="md"
													>
														<ButtonLabel>Scan More</ButtonLabel>
													</Button>
												</div>
											</motion.div>
										</motion.div>
									</div>
									<div className="z-[3] absolute scanner-modal-container inset-0 blur-[20px]" />
									<div className="z-[2] absolute bg-black/80 inset-0 backdrop-blur-[5px]" />
									<div
										className="z-[1] fixed inset-0 bg-cover bg-center bg-no-repeat "
										style={{
											backgroundImage: `url(${getAssetByCollectionName(issue?.assets, "cover_image")})`,
											filter: "grayscale(80%)",
										}}
									/>
								</motion.div>
							)}
						</div>
					</motion.div>
				</motion.div>
			)}
		</AnimatePresence>,
		document.body,
	);
}

export default Scan;
