import { IconAchievements } from "@/components/mvicons";
import Scroller from "./core/display/scroller";

export default function BadgeModal() {
	return (
		<div className="flex flex-col justify-center items-center w-full gap-6">
			<div className="flex flex-col w-full max-w-[520px]">
				<div className="flex flex-col justify-center items-center w-full gap-2">
					<div className="inline-flex flex-row items-center gap-4 text-white">
						<IconAchievements size={28} />
						<span className="text-xl text-white">Your Badges</span>
					</div>
					<div className="flex flex-col justify-center items-center w-full">
						<span className="font-quagmire text-center font-bold text-2xl text-white uppercase leading-8">
							Interact with macronauts...
						</span>
						<span className="font-quagmire font-bold text-2xl text-mvred-600 uppercase  leading-8">
							and earn badges!
						</span>
					</div>
				</div>
			</div>
			<Scroller className="p-6" height={360}>
				<div className="flex flex-col w-full gap-4">
					<div className="flex flex-row items-center w-full rounded-xl gap-4 md:gap-6 p-4 md:p-6 lg:p-8 bg-white bg-opacity-5">
						<div className="inline-flex shrink-0 self-start w-[100px] h-auto text-white">
							<img
								src="/badge-1.png"
								alt="Macroverse"
								className="w-full object-contain rounded-xl"
							/>
						</div>
						<div className="flex flex-col w-full max-w-[560px] gap-3">
							<span className="text-md font-semibold text-white leading-tight">
								Badge 1
							</span>
							<span className="text-sm text-white/70 leading-tight line-clamp-4">
								Unlock and collect special badges to show off your fandom,
								activity, and impeccable taste!
							</span>
						</div>
					</div>

					<div className="flex flex-row items-center w-full rounded-xl gap-4 md:gap-6 p-4 md:p-6 lg:p-8 bg-white bg-opacity-5">
						<div className="inline-flex shrink-0 self-start w-[100px] h-auto text-white">
							<img
								src="/badge-2.png"
								alt="Macroverse"
								className="w-full object-contain rounded-xl"
							/>
						</div>
						<div className="flex flex-col w-full max-w-[560px] gap-3">
							<span className="text-md font-semibold text-white leading-tight">
								Badge 2
							</span>
							<span className="text-sm text-white/70 leading-tight line-clamp-4">
								Unlock and collect special badges to show off your fandom,
								activity, and impeccable taste!
							</span>
						</div>
					</div>

					<div className="flex flex-row items-center w-full rounded-xl gap-4 md:gap-6 p-4 md:p-6 lg:p-8 bg-white bg-opacity-5">
						<div className="inline-flex shrink-0 self-start w-[100px] h-auto text-white">
							<img
								src="/badge-3.png"
								alt="Macroverse"
								className="w-full object-contain rounded-xl"
							/>
						</div>
						<div className="flex flex-col w-full max-w-[560px] gap-3">
							<span className="text-md font-semibold text-white leading-tight">
								Badge 3
							</span>
							<span className="text-sm text-white/70 leading-tight line-clamp-4">
								Unlock and collect special badges to show off your fandom,
								activity, and impeccable taste!
							</span>
						</div>
					</div>

					<div className="flex flex-row items-center w-full rounded-xl gap-4 md:gap-6 p-4 md:p-6 lg:p-8 bg-white bg-opacity-5">
						<div className="inline-flex shrink-0 self-start w-[100px] h-auto text-white">
							<img
								src="/badge-4.png"
								alt="Macroverse"
								className="w-full object-contain rounded-xl"
							/>
						</div>
						<div className="flex flex-col w-full max-w-[560px] gap-3">
							<span className="text-md font-semibold text-white leading-tight">
								Badge 4
							</span>
							<span className="text-sm text-white/70 leading-tight line-clamp-4">
								Unlock and collect special badges to show off your fandom,
								activity, and impeccable taste!
							</span>
						</div>
					</div>

					<div className="flex flex-row items-center w-full rounded-xl gap-4 md:gap-6 p-4 md:p-6 lg:p-8 bg-white bg-opacity-5">
						<div className="inline-flex shrink-0 self-start w-[100px] h-auto text-white">
							<img
								src="/badge-5.png"
								alt="Macroverse"
								className="w-full object-contain rounded-xl"
							/>
						</div>
						<div className="flex flex-col w-full max-w-[560px] gap-3">
							<span className="text-md font-semibold text-white leading-tight">
								Badge 5
							</span>
							<span className="text-sm text-white/70 leading-tight line-clamp-4">
								Unlock and collect special badges to show off your fandom,
								activity, and impeccable taste!
							</span>
						</div>
					</div>

					<div className="flex flex-row items-center w-full rounded-xl gap-4 md:gap-6 p-4 md:p-6 lg:p-8 bg-white bg-opacity-5">
						<div className="inline-flex shrink-0 self-start w-[100px] h-auto text-white">
							<img
								src="/badge-6.png"
								alt="Macroverse"
								className="w-full object-contain rounded-xl"
							/>
						</div>
						<div className="flex flex-col w-full max-w-[560px] gap-3">
							<span className="text-md font-semibold text-white leading-tight">
								Badge 6
							</span>
							<span className="text-sm text-white/70 leading-tight line-clamp-4">
								Unlock and collect special badges to show off your fandom,
								activity, and impeccable taste!
							</span>
						</div>
					</div>

					<div className="flex flex-row items-center w-full rounded-xl gap-4 md:gap-6 p-4 md:p-6 lg:p-8 bg-white bg-opacity-5">
						<div className="inline-flex shrink-0 self-start w-[100px] h-auto text-white">
							<img
								src="/badge-7.png"
								alt="Macroverse"
								className="w-full object-contain rounded-xl"
							/>
						</div>
						<div className="flex flex-col w-full max-w-[560px] gap-3">
							<span className="text-md font-semibold text-white leading-tight">
								Badge 7
							</span>
							<span className="text-sm text-white/70 leading-tight line-clamp-4">
								Unlock and collect special badges to show off your fandom,
								activity, and impeccable taste!
							</span>
						</div>
					</div>
				</div>
			</Scroller>
		</div>
	);
}
