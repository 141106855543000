import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconMaximize = ({
	size = 24,
	color = "currentColor",
	title = "mv-icon",
	className,
	...props
}: IconProps) => (
	<div
		className={clsx(
			"inline-flex shrink-0 justify-center items-center",
			className,
		)}
		style={{ width: size, height: size }}
	>
		<svg
			width="30"
			height="30"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<g opacity="1">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M0 0V11.5H4V4H11.5V0H0ZM18.5 0V4H26V11.5H30V0H18.5ZM30 18.5H26V26H18.5V30H30V18.5ZM11.5 30V26H4V18.5H0V30H11.5Z"
					fill={color}
				/>
			</g>
		</svg>
	</div>
);

export default IconMaximize;
