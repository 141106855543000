import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { mobileDarklandS1Pages } from "@/constants/mobile-darkland-s1-pages.ts";
import { IconArrowNarrowUp } from "@tabler/icons-react";
import { FreeMode, Mousewheel, Pagination } from "swiper/modules";

const ScrollReader = () => {
	const [animateDetails, setAnimateDetails] = useState(false); // Control animation state
	const [showDetails, setShowDetails] = useState(true);
	const [isVisible, setIsVisible] = useState(true);
	const [scrollY, setScrollY] = useState(0);

	const handleSwipeUp = () => {
		setAnimateDetails(true); // Animate top section details
		setTimeout(() => {
			setIsVisible(false); // Hide element permanently
			setShowDetails(false); // hide details permanently
		}, 500); // Match this duration with the animation duration
	};

	const handleWheel = (event) => {
		if (event.deltaY > 0) {
			setScrollY((prev) => prev - event.deltaY);

			if (scrollY < -15) {
				handleSwipeUp();
			}
		}
	};

	const SwiperUpTrigger = () => {
		return (
			<AnimatePresence>
				{isVisible && (
					<motion.div
						className="z-[4] absolute top-1/2 left-1/2 w-[160px] h-[160px] -ml-[80px] -mt-[80px] flex flex-col items-center justify-center shadow-lg cursor-pointer"
						onWheel={handleWheel} // Wheel event to control movement
						drag="y"
						dragConstraints={{ top: 0, bottom: 0 }} // Prevent dragging out of bounds
						dragElastic={0.3} // Elastic drag effect
						onDragEnd={(e, info) => {
							if (info.offset.y < -100) {
								handleSwipeUp(); // Trigger swipe up if dragged upwards significantly
							}
						}}
						initial={{ y: 0, opacity: 1 }}
						animate={{
							y: scrollY, // Animate to the current scrollY position
							opacity: animateDetails ? 0 : 1, // Fade out based on animateDetails
						}}
						exit={{
							opacity: 0, // Fade out when exiting
							transition: { duration: 0.3 },
						}}
						transition={{
							duration: 0.5, // Smooth animation duration
							ease: [0.33, 1, 0.68, 1], // Use a cubic-bezier easing for smooth motion
						}}
						style={{ display: animateDetails ? "none" : "flex" }}
					>
						<IconArrowNarrowUp className="text-white" size={100} stroke={1} />
						<span className="text-center text-white">
							Scroll Down or Swipe up to Start
						</span>
					</motion.div>
				)}
			</AnimatePresence>
		);
	};

	return (
		<div
			className="relative flex flex-col h-full w-full bg-black"
			onWheel={handleWheel}
		>
			{/* tap reader preview */}
			{showDetails && <SwiperUpTrigger />}

			{/* reader ui contents */}
			{showDetails && (
				<motion.div
					className="z-[3] absolute top-6 left-1/2 inline-flex flex-col w-auto gap-6"
					initial={{ y: 0, x: "-50%", opacity: 1 }}
					animate={
						animateDetails ? { y: -100, opacity: 0 } : { y: 0, opacity: 1 }
					}
					transition={{
						duration: 0.5,
						ease: [0.33, 1, 0.68, 1],
					}}
				>
					<div className="flex flex-col justify-center items-center w-full gap-3">
						<div className="flex justify-center items-center w-full p-4 rounded-md bg-white/30 text-white">
							Brand Name
						</div>
						<div className="inline-flex flex-row items-center divide-x-2">
							<span className="px-2 uppercase text-sm text-white/70 leading-tight">
								Season 1
							</span>
							<span className="px-2 uppercase text-sm text-white/70 leading-tight">
								Episode 1
							</span>
						</div>
					</div>
					<div className="flex flex-row items-center w-full">
						<span className="font-bold text-3xl text-white">11+</span>
						<div className="inline-flex flex-col w-auto py-1 pl-4 ml-4 border-l-2 border-white">
							<span className="text-white/70 leading-tight">
								Cartoon Violence
							</span>
							<span className="text-white/70 leading-tight">Mature Themes</span>
							<span className="text-white/70 leading-tight">
								Some Rating Item
							</span>
							<span className="text-white/70 leading-tight">
								Another Rating item
							</span>
						</div>
					</div>
				</motion.div>
			)}

			{showDetails && (
				<motion.div
					className="z-[2] absolute inset-0 w-full h-full bg-black"
					initial={{ opacity: 1 }}
					animate={animateDetails ? { opacity: 0 } : { opacity: 1 }}
					transition={{
						duration: 0.5,
						ease: [0.33, 1, 0.68, 1],
					}}
				/>
			)}

			{/* actual page viewer */}
			<div className="z-[1] flex w-full h-full bg-black">
				<Swiper
					slidesPerView={1}
					centeredSlides={false}
					allowTouchMove={true}
					direction="vertical" // Enable vertical direction
					mousewheel={true} // Enable mouse wheel scrolling
					freeMode={true} // Enable free-flowing scrolling
					spaceBetween={0}
					pagination={{
						type: "progressbar", // Define type
						clickable: false, // Make it clickable
					}}
					modules={[Pagination, Mousewheel, FreeMode]} // Include necessary modules
					className="scroll-reader w-screen min-h-screen"
				>
					{mobileDarklandS1Pages.map(({ src, altText }, index) => (
						<SwiperSlide
							key={index}
							className="flex justify-center w-full h-full bg-black"
						>
							<div className="flex flex-col justify-center items-center h-full">
								<img className="block h-full" src={src} alt={altText} />
							</div>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</div>
	);
};

export default ScrollReader;
