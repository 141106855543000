import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { IconChevronLeft, IconChevronRight } from "@/components/mvicons";
import { tabletCocS19Pages } from "@/constants/tablet-coc-s19-pages.ts";
import { IconArrowNarrowLeft } from "@tabler/icons-react";
import clsx from "clsx";
import { Navigation, Pagination } from "swiper/modules";

type ImageData = {
	src: string;
	altText: string;
};

const SwipeReader = () => {
	const [animateDetails, setAnimateDetails] = useState(false); // Control animation state
	const [showDetails, setShowDetails] = useState(true);
	const [isVisible, setIsVisible] = useState(true);

	const handleSwipeUp = () => {
		setAnimateDetails(true); // Animate top section details
		setTimeout(() => {
			setIsVisible(false); // Hide element permanently
			setShowDetails(false); // hide details permanently
		}, 500); // Match this duration with the animation duration
	};

	const SwiperLeftTrigger = () => {
		return (
			<AnimatePresence>
				{isVisible && (
					<motion.div
						className="z-[4] absolute top-1/2 left-1/2 w-[200px] h-[100px] -ml-[100px] -mt-[50px] flex flex-row items-center justify-center shadow-lg cursor-pointer"
						drag="x" // Enable horizontal dragging
						dragConstraints={{ left: 0, right: 0 }} // Limit drag to the left (right to left)
						dragElastic={0.3} // Elastic drag effect
						onDragEnd={(e, info) => {
							if (info.offset.x < -100) {
								// Fire handleSwipeUp when dragging significantly to the left
								handleSwipeUp();
							}
						}}
						initial={{ x: 0, opacity: 1 }}
						animate={{
							x: 0, // Keep the div centered
							opacity: animateDetails ? 0 : 1, // Fade out based on animateDetails
						}}
						exit={{
							opacity: 0, // Fade out when exiting
							transition: { duration: 0.3 },
						}}
						transition={{
							duration: 0.5, // Smooth animation duration
							ease: [0.33, 1, 0.68, 1], // Use a cubic-bezier easing for smooth motion
						}}
						style={{ display: animateDetails ? "none" : "flex" }}
					>
						<IconArrowNarrowLeft
							className="text-white shrink-0 -ml-2"
							size={100}
							stroke={1}
						/>
						<span className="text-left text-white">Swipe to Left to Start</span>
					</motion.div>
				)}
			</AnimatePresence>
		);
	};

	return (
		<div className="relative flex flex-col h-full w-full bg-black">
			{/* tap reader preview */}
			{showDetails && <SwiperLeftTrigger />}

			{/* reader ui contents */}
			{showDetails && (
				<motion.div
					className="z-[3] absolute top-6 left-1/2 inline-flex flex-col w-auto gap-6"
					initial={{ y: 0, x: "-50%", opacity: 1 }}
					animate={
						animateDetails ? { y: -100, opacity: 0 } : { y: 0, opacity: 1 }
					}
					transition={{
						duration: 0.5,
						ease: [0.33, 1, 0.68, 1],
					}}
				>
					<div className="flex flex-col justify-center items-center w-full gap-3">
						<div className="flex justify-center items-center w-full p-4 rounded-md bg-white/30 text-white">
							Brand Name
						</div>
						<div className="inline-flex flex-row items-center divide-x-2">
							<span className="px-2 uppercase text-sm text-white/70 leading-tight">
								Season 1
							</span>
							<span className="px-2 uppercase text-sm text-white/70 leading-tight">
								Episode 1
							</span>
						</div>
					</div>
					<div className="flex flex-row items-center w-full">
						<span className="font-bold text-3xl text-white">11+</span>
						<div className="inline-flex flex-col w-auto py-1 pl-4 ml-4 border-l-2 border-white">
							<span className="text-white/70 leading-tight">
								Cartoon Violence
							</span>
							<span className="text-white/70 leading-tight">Mature Themes</span>
							<span className="text-white/70 leading-tight">
								Some Rating Item
							</span>
							<span className="text-white/70 leading-tight">
								Another Rating item
							</span>
						</div>
					</div>
				</motion.div>
			)}

			{showDetails && (
				<motion.div
					className="z-[2] absolute inset-0 w-full h-full bg-black"
					initial={{ opacity: 1 }}
					animate={animateDetails ? { opacity: 0 } : { opacity: 1 }}
					transition={{
						duration: 0.5,
						ease: [0.33, 1, 0.68, 1],
					}}
				/>
			)}

			{/* actual page viewer */}
			<div className="z-[1] flex w-full h-full bg-black">
				<Swiper
					slidesPerView="auto"
					centeredSlides={true}
					spaceBetween={4}
					allowTouchMove={true}
					pagination={{
						type: "progressbar", // Define type
						clickable: false, // Make it clickable
					}}
					navigation={{
						enabled: true,
						prevEl: ".swiper-button-prev", // Customize previous button
						nextEl: ".swiper-button-next", // Customize next button
					}}
					modules={[Pagination, Navigation]} // Include necessary modules
					className="swipe-reader w-auto h-full"
				>
					<div className="z-[50] absolute inset-0 top-1/2 -translate-y-1/2 h-[40px] flex justify-between w-full px-6 md:px-24 pointer-events-none">
						<div className="swiper-button-prev inline-flex justify-center items-center w-[40px] h-[40px] rounded-full outline-none border-none ring-0 bg-white/20 hover:bg-white/30 backdrop-blur text-white pointer-events-auto cursor-pointer">
							<IconChevronLeft
								className="-translate-x-[2px]"
								size={10}
								stroke={8}
							/>
						</div>
						<div className="swiper-button-next inline-flex justify-center items-center w-[40px] h-[40px] rounded-full outline-none border-none ring-0 bg-white/20 hover:bg-white/30 backdrop-blur text-white pointer-events-auto cursor-pointer">
							<IconChevronRight
								className="translate-x-[2px]"
								size={10}
								stroke={8}
							/>
						</div>
					</div>

					{/* for single image per slide */}
					{/* just comment do not remove */}
					{/*{tabletCocS19Pages.map(({ src, altText }, index) => (
						<SwiperSlide
							key={index}
							className="flex justify-center items-center w-auto h-full max-w-[90%]"
						>
							<div className="flex w-auto h-full">
								<img
									className="w-full h-full object-contain"
									src={src}
									alt={altText}
								/>
							</div>
						</SwiperSlide>
					))}*/}

					{/* for hybrid image count (with cover page) */}
					{/* just comment do not remove */}
					{tabletCocS19Pages
						.reduce<ImageData[][]>((acc, curr, index) => {
							if (index === 0) {
								acc.push([curr]); // First slide has one image
							} else if (index % 2 === 1) {
								acc.push([curr, tabletCocS19Pages[index + 1]]); // Pair the rest
							}
							return acc;
						}, [])
						.map((pair, index) => (
							<SwiperSlide
								key={index}
								className="flex justify-center items-center w-auto h-full max-w-[90%]"
							>
								<div className="flex w-auto h-[300px] sm:h-[400px] md:h-[500px] lg:h-[650px] xl:h-[900px] 2xl:h-[1040px] justify-center">
									{pair.map(
										(img, i) =>
											img && ( // Ensure the second image exists in pairs
												<img
													key={i}
													className={clsx("object-contain h-full", {
														"w-full": pair.length === 1,
														"w-1/2": pair.length !== 1,
													})}
													src={img.src}
													alt={img.altText}
												/>
											),
									)}
								</div>
							</SwiperSlide>
						))}
				</Swiper>
			</div>
		</div>
	);
};

export default SwipeReader;
